import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {IsValidModal} from "Views/IndividualNeeds/IsValidModal";
import {ObservationsModal} from "Views/IndividualNeeds/ObservationsModal";
import {exportAdminInscriptionSpecialNeeds, searchAdminInscriptionSpecialNeeds} from "Api/Query/SpecialNeedQuery";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import Select from "react-select";
import {SpecialNeedSelect} from "Components/SpecialNeed/SpecialNeedSelect";
import {UserObservationsModal} from "./UserObservationsModal";
import {useQuery} from "react-query";
import {appLoading} from "../../Store/loadingSlice";
import {Notificator} from "../../Services/Notificator/Notificator";
import {RefreshSpinIcon} from "../../Components/Icons/RefreshSpinIcon";
import {useAppDispatch} from "../../hooks";

export const AdminIndividualNeedsList: React.FC = () => {

    const validatedFilterOptions = [
        {label: 'Todos', value: null},
        {label: 'Sí', value: 1},
        {label: 'No', value: 0},
    ]
    const dispatch = useAppDispatch()

    const [refreshSwitch, setRefreshSwitch] = useState<boolean>(false)
    const [showIsValidModal, setShowIsValidModal] = useState<boolean>(false)
    const [showObservationsModal, setShowObservationsModal] = useState<boolean>(false)
    const [showUserObservationsModal, setUserShowObservationsModal] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<object | null>(null)
    const [customFiltersData, setCustomFiltersData] = useState<{ [key: string]: any }>({})
    const [customFiltersDataToApply, setCustomFiltersDataToApply] = useState<{ [key: string]: any }>({})
    const [validatedFilter, setValidatedFilter] = useState<any>(validatedFilterOptions[0])
    const [typeFilter, setTypeFilter] = useState<any>(null)
    const [exporting, setExporting] = useState<boolean>(false)

    const applyFilterChanges = () => {
        const parsedFilters: any = {}

        if (customFiltersData.q) {
            parsedFilters['q'] = customFiltersData.q
        }

        if ([1, 0].includes(customFiltersData.validated)) {
            parsedFilters['validated'] = customFiltersData.validated
        }

        if (customFiltersData.type) {
            parsedFilters['type'] = customFiltersData.type
        }

        setCustomFiltersDataToApply(parsedFilters)
    }

    const headers: string[] = [
        'NIÑO/A',
        'TUTOR/A',
        'CENTRO',
        'ACTIVIDADES',
        'INSCRIPCIÓN',
        'ARCHIVOS ADJUNTOS',
        'TIPO',
        'COMENTARIOS',
        'VALIDADO',
        'OBSERVACIONES',
        'OBSERVACIONES AL USUARIO',
    ]

    const editIsValidated = (item: object) => {
        setSelectedItem(item)
        setShowIsValidModal(true)
    }

    const editObservations = (item: object) => {
        setSelectedItem(item)
        setShowObservationsModal(true)
    }

    const editUserObservations = (item: object) => {
        setSelectedItem(item)
        setUserShowObservationsModal(true)
    }

    const closeEditIsValidated = () => {
        setSelectedItem(null)
        setShowIsValidModal(false)
    }

    const excelExport = () => {
        setExporting(true)
        exportQuery.refetch()
    }

    const downloadCsv = (csvContent: string, filename: string) => {
        const blob = new Blob([csvContent], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportQuery = useQuery({
        enabled: false,
        queryKey: ['exportAdminInscriptionSpecialNeeds'],
        queryFn: () => exportAdminInscriptionSpecialNeeds(customFiltersDataToApply),
        onSuccess: (response: any) => {
            const responseData = response.data
            const filename = response.headers['content-language'] ?? 'inscripciones.csv'
            downloadCsv(responseData, filename)
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        },
        onError: () => {
            Notificator.error('Se ha producido un error inesperado.')
            setExporting(false)
            dispatch(appLoading({value: false, text: 'Cargando'}))
        }
    })

    return (
        <RequireAuth>
            <DefaultContainer fluid={true}>

                <Col id={"mainRequestList"}>

                    <AdminCrudList
                        fluidContainer={true}
                        refreshSwitch={refreshSwitch}
                        canSelectMultipleRows={false}
                        title={"Listado de necesidades especiales"}
                        headers={headers}
                        queryMethod={searchAdminInscriptionSpecialNeeds}
                        queryName={"searchAdminInscriptionSpecialNeeds"}
                        deleteMethod={() => console.log(1)}
                        customFiltersData={customFiltersDataToApply}
                        exportChildren={
                            <Button
                                className={"purple-btn mx-4"}
                                onClick={excelExport}
                                disabled={exporting}>
                                {exporting && <><RefreshSpinIcon/>&nbsp;&nbsp;</>}
                                <>Exportar</>
                            </Button>
                        }
                        customFilters={
                            <>
                                <Col xs={12} sm={4}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Buscar
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(e: any) => {
                                                const value = e.target.value ?? ''
                                                setCustomFiltersData({...customFiltersData, q: value})
                                            }}
                                            value={customFiltersData.q ?? ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Validado
                                        </Form.Label>
                                        <Select
                                            placeholder={"Selecciona una opción"}
                                            options={validatedFilterOptions}
                                            value={validatedFilter}
                                            onChange={(e: any) => {
                                                setValidatedFilter(e)

                                                if (e.value !== null) {
                                                    setCustomFiltersData({...customFiltersData, validated: e.value})
                                                } else {
                                                    let newFilters: any = {}
                                                    Object.keys(customFiltersData).map((key: string) => {
                                                        if (key !== 'validated') {
                                                            newFilters = {...newFilters, key: customFiltersData[key]}
                                                        }
                                                    })
                                                    setCustomFiltersData(newFilters)
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Form.Group controlId={"queryFilter"}>
                                        <Form.Label>
                                            Tipo
                                        </Form.Label>
                                        <SpecialNeedSelect
                                            onChange={(option: any) => {
                                                setTypeFilter(option)

                                                if (option && option.value) {
                                                    setCustomFiltersData({...customFiltersData, type: option.value})
                                                } else {
                                                    let newFilters: any = {}
                                                    Object.keys(customFiltersData).map((key: string) => {
                                                        if (key !== 'type') {
                                                            newFilters = {...newFilters, key: customFiltersData[key]}
                                                        }
                                                    })
                                                    setCustomFiltersData(newFilters)
                                                }
                                            }}
                                            value={typeFilter}
                                        />
                                    </Form.Group>
                                </Col>
                                <Row className={"mt-2"}>
                                    <Col>
                                        <Button
                                            className={"purple-btn"}
                                            onClick={applyFilterChanges}
                                        >Buscar</Button>
                                        &nbsp;
                                        <Button
                                            variant={"secondary"}
                                            onClick={() => {
                                                setValidatedFilter(null)
                                                setTypeFilter(null)
                                                setCustomFiltersData({})
                                                setCustomFiltersDataToApply({})
                                            }
                                            }
                                        >Limpiar filtros</Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        tableRow={(item: any, key: number) =>
                            <>
                                <td>
                                    <Link
                                        to={ROUTE_PATHS.ADMIN_KIDS_EDIT.replace(":id", item.child.id)}
                                        target={"_blank"}
                                    >
                                        {item.child.name}
                                    </Link>
                                </td>
                                <td>
                                    <p>{item.tutor.name}</p>
                                    <p>Telf: {item.tutor.phoneNumbers}</p>
                                </td>
                                <td>{item.center.name}</td>
                                <td>
                                    {
                                        item.activities.map((act: any, k: number) => {
                                            return (<>{act.name}<br/></>)
                                        })
                                    }
                                </td>
                                <td>
                                    {
                                        item.inscription && <>
                                            <Link
                                                target={"_blank"}
                                                to={ROUTE_PATHS.ADMIN_INSCRIPTION_DETAIL.replace(":id", item.inscription.id)}>{item.inscription.id}</Link>
                                            <br/>
                                            <Badge
                                                bg={item.inscription?.status?.color}>{item.inscription?.status?.name.toUpperCase()}</Badge>
                                        </>
                                    }
                                </td>
                                <td>
                                    <ul>
                                        {item.specialNeed.attachments?.map((attachment: any, key: number) => {
                                            return (
                                                <li key={key}>
                                                    <a
                                                        href={attachment.url}
                                                        target={"_blank"}
                                                    >{attachment.name}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </td>
                                <td>{item.specialNeed.name}</td>
                                <td>
                                    {item.specialNeed.comments}
                                </td>
                                <td>
                                    {item.isValidated ? <>Sí</> : <>No</>}
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer"
                                        onClick={() => editIsValidated(item)}
                                    />
                                </td>
                                <td>
                                    {item.observations}
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer"
                                        onClick={() => editObservations(item)}
                                    />
                                </td>
                                <td>
                                    {item.userObservations}
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="pointer"
                                        onClick={() => editUserObservations(item)}
                                    />
                                </td>
                            </>
                        }

                    />

                </Col>

            </DefaultContainer>

            <IsValidModal
                show={showIsValidModal}
                onClose={closeEditIsValidated}
                item={selectedItem}
                onUpdated={() => setRefreshSwitch(!refreshSwitch)}
            />
            <ObservationsModal
                show={showObservationsModal}
                onClose={() => setShowObservationsModal(false)}
                item={selectedItem}
                onUpdated={() => setRefreshSwitch(!refreshSwitch)}
            />
            <UserObservationsModal
                show={showUserObservationsModal}
                onClose={() => setUserShowObservationsModal(false)}
                item={selectedItem}
                onUpdated={() => setRefreshSwitch(!refreshSwitch)}
            />

        </RequireAuth>
    )
}
